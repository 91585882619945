import * as React from "react";
import Icon from "../Icon";

interface IButtonProps {
  primary?: boolean;
  secondary?: boolean;
  hollow?: boolean;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  icon?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  url?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
  style?: { [key: string]: string };
  className?: string;
  children?: any;
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const {
    primary,
    secondary,
    hollow,
    tiny,
    small,
    large,
    expanded,
    disabled,
    icon,
    iconLeft,
    iconRight,
    url,
    openInNewTab,
    onClick,
    style,
    className,
  } = props;

  const getClasses = () => {
    let classes = `button`;
    if (tiny) classes += ` tiny`;
    if (small) classes += ` small`;
    if (large) classes += ` large`;
    if (expanded) classes += ` expanded`;
    if (primary) classes += ` primary`;
    if (secondary) classes += ` secondary`;
    if (hollow) classes += ` hollow`;

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  const getContent = () => {
    let content = props.children;

    if (icon) {
      if (iconRight && !iconLeft)
        content = (
          <>
            {content} <Icon icon={icon} right />
          </>
        );
      else
        content = (
          <>
            <Icon icon={icon} left />
            {content}
          </>
        );
    }
    return content;
  };

  if (url)
    return (
      <a
        href={url}
        target={openInNewTab ? `_blank` : undefined}
        rel={openInNewTab ? `noopener noreferrer` : undefined}
        style={style}
        className={getClasses()}
        onClick={(e) => {
          if (disabled) e.preventDefault();
        }}
      >
        {getContent()}
      </a>
    );
  else if (onClick)
    return (
      <button
        style={style}
        className={getClasses()}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        disabled={disabled}
      >
        {getContent()}
      </button>
    );

  return null;
};

export default Button;
