import * as React from "react";
import * as FDN from "src/core";
import {
  PrFormVisibleColumns,
  TActionsPr,
  IPurchaseRequisition,
  PrFormSearchProps,
  IFreeItem,
} from "src/types/pr.types";
import { IProductBaseUnit, IProductCategories, IProductUnits, ISuppliers } from "src/types/products.types";
import PrFormProductsList from "./PrFormProductsList";
import PrFormPriceTotal from "./PrFormPriceTotal";
import PrFormSearch from "./PrFormSearch";
import PrFormOptions from "./PrFormOptions";
import PrFreeItems from "./PrFreeItems";
import PrFreeItemsPopup from "./PrFreeItemsPopup";

interface IPrFormProps {
  location: "admin" | "client";
  pr: IPurchaseRequisition;
  search: PrFormSearchProps;
  editMode: boolean;
  categories?: IProductCategories;
  prFormVisibleColumns: PrFormVisibleColumns;
  editFreeItem?: IFreeItem;
  productUnits?: IProductUnits;
  showSupplier: boolean;
  suppliers: ISuppliers;
  baseUnits: IProductBaseUnit[];
  actions: TActionsPr;
}

const PrForm: React.FunctionComponent<IPrFormProps> = ({
  location,
  pr,
  search,
  editMode,
  prFormVisibleColumns,
  categories,
  editFreeItem,
  productUnits,
  baseUnits,
  showSupplier,
  suppliers,
  actions,
}) => {
  return (
    <div className="__pr-form">
      <PrFormSearch
        search={search}
        categories={categories ?? []}
        suppliers={suppliers}
        showSupplier={showSupplier}
        actions={actions}
      />

      {(!categories || categories.length === 0) && (!pr.freeItems || pr.freeItems.length === 0) ? (
        <p>
          <em>{FDN.I18n.t("pr.noProducts")}</em>
        </p>
      ) : (
        <>
          {editMode === true && (
            <div className="flex justify-center pb-4">
              <button
                className="border rounded-lg border-primary bg-primary text-white px-6 py-3 cursor-pointer hover:opacity-75"
                onClick={() => actions.onSelectFreeItem("new")}
              >
                <FDN.Icon icon="plus-circle" left /> {FDN.I18n.t("pr.freeitems.buttons.add.label")}
              </button>
            </div>
          )}

          <div className="mb-4">
            <PrFormProductsList
              location={location}
              products={actions.getProductsForList()}
              pr={pr}
              editMode={editMode}
              prFormVisibleColumns={prFormVisibleColumns}
              showSupplier={showSupplier}
              actions={actions}
            />

            <PrFreeItems
              location={location}
              pr={pr}
              editMode={editMode}
              prFormVisibleColumns={prFormVisibleColumns}
              showSupplier={showSupplier}
              actions={actions}
            />
          </div>

          {(actions.getProductsForList("NOT_IN_STOCK").length > 0 ||
            actions.getFreeItemsForList("NOT_IN_STOCK").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.NOT_IN_STOCK.title")}
              </h3>
              <div className="mb-4">
                <PrFormProductsList
                  location={location}
                  showOnlyStatus="NOT_IN_STOCK"
                  products={actions.getProductsForList("NOT_IN_STOCK")}
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrFreeItems
                  location={location}
                  showOnlyStatus="NOT_IN_STOCK"
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          {(actions.getProductsForList("SHORTAGE").length > 0 ||
            actions.getFreeItemsForList("SHORTAGE").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.SHORTAGE.title")}
              </h3>
              <div className="mb-4">
                <PrFormProductsList
                  location={location}
                  showOnlyStatus="SHORTAGE"
                  products={actions.getProductsForList("SHORTAGE")}
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrFreeItems
                  location={location}
                  showOnlyStatus="SHORTAGE"
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          {(actions.getProductsForList("QUALITY").length > 0 || actions.getFreeItemsForList("QUALITY").length > 0) && (
            <>
              <h3 className="text-base">
                <span className="text-sm">
                  <FDN.Icon icon="exclamation-circle" />
                </span>{" "}
                {FDN.I18n.t("pr.product.QUALITY.title")}
              </h3>
              <div className="mb-4">
                <PrFormProductsList
                  location={location}
                  showOnlyStatus="QUALITY"
                  products={actions.getProductsForList("QUALITY")}
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
                <PrFreeItems
                  location={location}
                  showOnlyStatus="QUALITY"
                  pr={pr}
                  editMode={editMode}
                  prFormVisibleColumns={prFormVisibleColumns}
                  showSupplier={showSupplier}
                  actions={actions}
                />
              </div>
            </>
          )}

          <PrFormPriceTotal pr={pr} />

          <PrFormOptions pr={pr} editMode={editMode} actions={actions} />

          {editFreeItem && productUnits ? (
            <PrFreeItemsPopup
              freeItem={editFreeItem}
              missingFieldsFreeItem={[]}
              productUnits={productUnits}
              products={actions.getProductsForList()}
              baseUnits={baseUnits}
              actions={actions}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default PrForm;
