import React from "react";

interface IIconProps {
  icon: string;
  left?: boolean;
  right?: boolean;
  style?: { [key: string]: string };
  className?: string;
}

/**
 * Font Awesome Icon + CSS.gg Icon
 */
const Icon: React.FunctionComponent<IIconProps> = (props) => {
  const { icon, left, right, style, className } = props;

  const getClasses = () => {
    let classes = `__ptfdn-icon fa fa-${icon} ${left ? `icon-left` : ``} ${
      right ? `icon-right` : ``
    }`;

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  const getGGClasses = () => {
    let classes = `__ptfdn-icon ${icon} ${left ? `icon-left` : ``} ${right ? `icon-right` : ``}`;

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  if (icon.substring(0, 3) === "gg-") return <i className={getGGClasses()} style={style} />;
  else return <span className={getClasses()} style={style} />;
};

export default Icon;
