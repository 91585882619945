import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import PrStatus from "../../prs/parts/PrStatus";
import PrForm from "../../prs/parts/PrForm";
import useServicePr from "src/services/PrService";
import PrClientLogo from "../../prs/parts/PrClientLogo";
import PrClientAddress from "../../prs/parts/PrClientAddress";
import { userHasPermission } from "src/core/AdminService/helpers";
import useServiceAdminPr from "src/services/admin/AdminPrService";
import PrStatusChange from "./parts/PrStatusChange";
import UrlService from "src/core/UrlService";
import PrDeliveryDate from "./parts/PrDeliveryDate";
import Api from "src/api/Api";
import PrInfoBlock from "./parts/PrInfoBlock";
import { IProductCategories, ISuppliers } from "src/types/products.types";

const AdminPrView: React.FunctionComponent = () => {
  const {
    pr: initialPr,
    user,
    categories,
    search,
    prFormVisibleColumns,
    suppliers,
    baseUnits,
    actions,
  } = useServicePr();
  const {
    pr,
    popupStatusChangeVisible,
    popupDeliveryDateVisible,
    actions: adminActions,
  } = useServiceAdminPr(initialPr);

  if (!pr || !pr.client || !suppliers || !baseUnits) return <FDN.Loading />;

  const products = actions.getProductsForList();

  let filteredSuppliers: ISuppliers = [];

  for (const prProduct of pr.products ?? []) {
    const product = products.find((product) => product.identifier === prProduct.productIdentifier);
    if (product && product.suppliers) {
      filteredSuppliers.push(...product.suppliers);
    }
  }

  filteredSuppliers = filteredSuppliers.filter(
    (supplier, index, self) => index === self.findIndex((s) => s.identifier === supplier.identifier)
  );

  let filteredCategories: IProductCategories = [];

  for (const prProduct of pr.products ?? []) {
    const product = products.find((product) => product.identifier === prProduct.productIdentifier);
    if (product && product.categoryIdentifier) {
      const category = categories?.find((category) => category.identifier === product.categoryIdentifier);
      if (category) {
        filteredCategories.push(category);
      }
    }
  }

  filteredCategories = filteredCategories.filter(
    (supplier, index, self) => index === self.findIndex((s) => s.identifier === supplier.identifier)
  );

  return (
    <div className="__admin-prs">
      <AdminLayout page="admin-purchaserequisitions" selectedNav="purchaserequisitions">
        <div className="_prs-view-topline">
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={12} md={12} className="text-left">
                <div className="_prs-view-topline-logo">&nbsp;</div>
              </FDN.Cell>
              <FDN.Cell sm={12} md={12} className="text-right">
                <PrClientLogo client={pr.client} />
                <PrClientAddress client={pr.client} />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="_prs-admin-pr-buttons">
          <div className="_prs-admin-pr-buttons-left">
            {pr.pdfPath ? (
              <a
                href={
                  new Api().getEndpoint("purchaserequisitions.pdfDownload", {
                    clientIdentifier: pr.clientIdentifier,
                    identifier: pr.identifier,
                  }) as string
                }
                target="_blank"
                rel="noopener noreferrer"
                className="primary hollow button"
              >
                <FDN.Icon icon="file-pdf-o" left /> {FDN.I18n.t("adminPr.buttons.pdf.label")}
              </a>
            ) : null}

            {pr.csvPath ? (
              <a
                href={
                  new Api().getEndpoint("purchaserequisitions.csvDownload", {
                    clientIdentifier: pr.clientIdentifier,
                    identifier: pr.identifier,
                  }) as string
                }
                target="_blank"
                rel="noopener noreferrer"
                className="primary hollow button"
              >
                <FDN.Icon icon="table" left /> {FDN.I18n.t("adminPr.buttons.csv.label")}
              </a>
            ) : null}
          </div>
          <div className="_prs-admin-pr-buttons-right">
            {userHasPermission(user, "adminPurchaseRequisitionsEdit") ? (
              <>
                {pr.isCancelable ? (
                  <button className="alert hollow button" onClick={() => adminActions.onCancelPr()}>
                    <FDN.Icon icon="times-circle" left /> {FDN.I18n.t("adminPr.buttons.cancel.label")}
                  </button>
                ) : (
                  <>
                    {pr.status === "CANCELED" ? (
                      <button className="alert hollow button" onClick={() => adminActions.onUndoCancelPr()}>
                        <FDN.Icon icon="times-circle" left /> {FDN.I18n.t("adminPr.buttons.undoCancel.label")}
                      </button>
                    ) : null}
                  </>
                )}
              </>
            ) : null}

            {!["COMPLETED", "CANCELED"].includes(pr.status) &&
            userHasPermission(user, "adminPurchaseRequisitionsEdit") ? (
              <button className="primary hollow button" onClick={() => adminActions.showDeliveryDatePopup()}>
                <FDN.Icon icon="truck" left /> {FDN.I18n.t("adminPr.buttons.deliveryDate.label")}
              </button>
            ) : null}

            {!["CANCELED"].includes(pr.status) && userHasPermission(user, "adminPurchaseRequisitionsEdit") ? (
              <button className="primary button" onClick={() => adminActions.showStatusChangePopup()}>
                <FDN.Icon icon="edit" left /> {FDN.I18n.t("adminPr.buttons.changeStatus.label")}
              </button>
            ) : null}
          </div>
        </div>
        <div>&nbsp;</div>
        <AdminHeader
          title={`#${pr.identifier} | ${pr.client?.name}`}
          backButton={{
            url: UrlService.url("admin.purchaserequisitions.index"),
          }}
        />

        <div className="flex md:justify-end -mt-4">
          <div className="w-full md:w-max text-right">
            <PrInfoBlock pr={pr} location="web" />
          </div>
        </div>

        {/* <FDN.Box noPadding>
          <div className="pr-pdf-infobox">
            <div className="pr-pdf-infobox-date">
              <FDN.Icon icon="calendar" left /> {FDN.I18n.t("pr.date")}:{" "}
              {FDN.formatDateTime(pr.requisitionDate, { useHumanReadableDate: false })}
            </div>
            <div className="pr-pdf-infobox-delivery">
              <FDN.Icon icon="truck" left /> {FDN.I18n.t("pr.options.delivery.delivery.label")}:{" "}
              {pr.desiredDeliveryType === "DESIRED" ? (
                <>{FDN.formatDate(pr.desiredDeliveryDate)}</>
              ) : (
                <>{FDN.I18n.t(`pr.options.delivery.select.${pr.desiredDeliveryType}.label`)}</>
              )}
            </div>
            <div className="pr-pdf-infobox-scheduleddelivery">
              {pr.statusDateARRIVED ? (
                <div className="success-text">
                  <FDN.Icon icon="check" left /> {FDN.I18n.t("adminPr.isArrived.label")}:{" "}
                  {FDN.formatDateTime(pr.statusDateARRIVED, { useHumanReadableDate: false })}
                </div>
              ) : (
                <>
                  <FDN.Icon icon="truck" left /> {FDN.I18n.t("pr.options.delivery.scheduledDelivery.label")}:{" "}
                  {pr.scheduledDeliveryDate ? (
                    <>{FDN.formatDate(pr.scheduledDeliveryDate)}</>
                  ) : (
                    <FDN.Icon icon="question" />
                  )}
                </>
              )}
            </div>
          </div>
        </FDN.Box> */}

        <div className="py-4">
          <PrStatus pr={pr} location="view" />
        </div>

        <PrForm
          location="admin"
          pr={pr}
          search={search}
          editMode={false}
          categories={filteredCategories}
          prFormVisibleColumns={prFormVisibleColumns}
          showSupplier={true}
          suppliers={filteredSuppliers}
          baseUnits={baseUnits}
          actions={actions}
        />

        {popupStatusChangeVisible === true && <PrStatusChange pr={pr} adminActions={adminActions} />}
        {popupDeliveryDateVisible === true && <PrDeliveryDate pr={pr} adminActions={adminActions} />}
      </AdminLayout>
    </div>
  );
};

export default AdminPrView;
