import { isArray } from "lodash";

/**
 *
 * HELPER FOR JOI VALIDATION FROM API SERVER
 *
 */

export const getValidationMissingFields = (joiValidationError?: {
  [key: string]: any;
}): string[] => {
  const missing: string[] = [];

  if (joiValidationError && joiValidationError.details && isArray(joiValidationError.details)) {
    for (const detail of joiValidationError.details) {
      if (detail.context?.key) missing.push(detail.context.key as string);
    }
  }

  return missing;
};

export const isEmail = (email?: string | null) =>
  email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) : false;
