import * as React from "react";
import ToolTip from "../ToolTip";
import { II18nCore, UserOnlineStatus } from "../../../types/types";

interface IOnlineStatusIndicatorProps {
  userIdentifier?: string;
  onlineStatus?: UserOnlineStatus;
  size?: number;
  privacyShowOnlineStatus?: boolean;
  SOCKETIO?: any;
  I18n?: II18nCore;
}

const OnlineStatusIndicator: React.FunctionComponent<IOnlineStatusIndicatorProps> = ({
  userIdentifier,
  onlineStatus = "UNKNOWN",
  size = 15,
  privacyShowOnlineStatus = true,
  SOCKETIO,
  I18n,
}) => {
  const [userOnlineStatus, setUserOnlineStatus] = React.useState<UserOnlineStatus>("UNKNOWN");

  // If userIdentifier is given, ignore onlineStatus prop
  React.useEffect(() => {
    if (userIdentifier && privacyShowOnlineStatus === true) {
      const userOnlineStatus = SOCKETIO?.getUserOnlineStatus(userIdentifier);
      setUserOnlineStatus(userOnlineStatus);
    }
  }, [userIdentifier, SOCKETIO?.getUserOnlineStatus(userIdentifier || "unknown")]);

  React.useEffect(() => {
    if (!userIdentifier && privacyShowOnlineStatus === true) {
      setUserOnlineStatus(onlineStatus);
    }
  }, [onlineStatus]);

  if (!userOnlineStatus || userOnlineStatus === "UNKNOWN") return null;

  return (
    <ToolTip tooltip={I18n ? I18n.t(`main.onlinestatus.${userOnlineStatus}`) : userOnlineStatus}>
      <span
        style={{ width: `${size}px`, height: `${size}px` }}
        className={`__onlinestatusindicator __onlinestatusindicator-status-${userOnlineStatus}`}
      />
    </ToolTip>
  );
};

export default OnlineStatusIndicator;
