import * as React from "react";
import Icon from "../Icon";

const defaultIcons = {
  info: "info-circle",
  warning: "exclamation-circle",
  alert: "exclamation-triangle",
  success: "check",
};

interface ICalloutBoxProps {
  type: "info" | "warning" | "alert" | "success";
  icon?: string | null;
  className?: string;
  style?: { [key: string]: string };
  children: React.ReactNode;
}

const CalloutBox: React.FunctionComponent<ICalloutBoxProps> = (props) => {
  const icon = <Icon icon={props.icon ? props.icon : defaultIcons[props.type]} />;

  return (
    <div className={`${props.type} callout __calloutbox ${props.className}`} style={props.style}>
      <div className={`__calloutbox-icon ${icon ? `with-icon` : `without-icon`}`}>{icon}</div>
      <div className={`__calloutbox-content ${icon ? `with-icon` : `without-icon`}`}>
        {props.children}
      </div>
    </div>
  );
};

export default CalloutBox;
