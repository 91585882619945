import * as React from "react";
import { Tooltip } from "react-tooltip";

type PlacesType = "top" | "right" | "bottom" | "left";

interface IToolTipsProps {
  tooltip?: string | number;
  place?: PlacesType;
  children: React.ReactNode;
}

const ToolTip: React.FunctionComponent<IToolTipsProps> = ({ tooltip, place = "top", children }) => {
  const randomId = `tooltip_${Math.floor(Math.random() * 1000000)}`;

  return (
    <>
      {/*<a className="__ptfdn-tooltip-element">{children}</a>
      <ReactToolTip
        anchorSelect="__ptfdn-tooltip-element"
        place={place as PlacesType}
        className="__tooltip"
      >
        {tooltip}
  </ReactToolTip>*/}
      <span
        data-tooltip-id={randomId}
        data-tooltip-content={tooltip ? (tooltip as string) : undefined}
        data-tooltip-place={place}
      >
        {children}
      </span>
      <Tooltip id={randomId} />
    </>
  );
};

export default ToolTip;
