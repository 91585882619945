import * as React from "react";

interface IRowProps {
  margin?: string;
  padding?: string;
  style?: { [key: string]: string | number };
  className?: string;
  children?: any;
}

const Row: React.FunctionComponent<IRowProps> = (props) => {
  const { margin, padding, style, className } = props;

  const getClasses = () => {
    let classes = `grid-x`;

    if (margin) {
      if (margin === "x") classes += " grid-margin-x";
      else if (margin === "y") classes += " grid-margin-y";
      else if (margin === "xy") classes += " grid-margin-x grid-margin-y";
    }

    if (padding) {
      if (padding === "x") classes += " grid-padding-x";
      else if (padding === "y") classes += " grid-padding-y";
      else if (padding === "xy") classes += " grid-padding-x grid-padding-y";
    }

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
};

export default Row;
