import * as React from "react";

interface IGridProps {
  full?: boolean;
  fluid?: boolean;
  style?: { [key: string]: string | number };
  className?: string;
  children?: any;
}

const Grid: React.FunctionComponent<IGridProps> = (props) => {
  const { full, fluid, style, className } = props;

  const getClasses = () => {
    return `${full ? "full" : ""} ${fluid ? "fluid" : ""} grid-container ${
      className || ""
    }`;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
};

export default Grid;
