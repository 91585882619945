import React, { useEffect, useState } from "react";
import * as FDN from "src/core";
import useServiceCore from "./CoreService";
import PrApi from "src/api/PrApi";
import StatusCode from "src/config/statuscodes";
import { I18n } from "src/core";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";
import { userHasPrPermission } from "src/core/AdminService/helpers";
import {
  IFreeItem,
  IFreeItems,
  IPrProduct,
  IPrProductStatusType,
  IPurchaseRequisition,
  PrFormProductsView,
  PrFormSearchProps,
  PrFormVisibleColumns,
  ProcessedFreeItemsCsvData,
  TActionsPr,
} from "src/types/pr.types";
import {
  IProduct,
  IProductBaseUnit,
  IProductCategories,
  IProductUnits,
  IProducts,
  ISuppliers,
} from "src/types/products.types";
import UrlService from "src/core/UrlService";
import { IClient } from "src/types/clients.types";
import AdminApi from "src/api/AdminApi";
import { FileUploadFile } from "src/components/main/ImageUpload/types";
import FilesApi from "src/api/FilesApi";
import { IFile } from "src/types/types";
import { showUnit } from "src/core/helpers/pr.helpers";

const defaultPrFormVisibleColumns = [
  "amount",
  "productName",
  "unit",
  "productCategory",
  "supplier",
  "priceUnit",
  "priceTotal",
];

const initSearch: PrFormSearchProps = {
  text: "",
  supplier: "",
  category: "",
  sortBy: "productCategory_asc",
};

const useServicePr = () => {
  const [pr, setPr] = useState<IPurchaseRequisition>();
  const [categories, setCategories] = useState<IProductCategories>();

  const [client, setClient] = useState<IClient>();

  const [virginPrProduct, setVirginPrProduct] = useState<IPrProduct>();

  const [formProductsView, setFormProductsView] = useState<PrFormProductsView>("all");
  const [prFormVisibleColumns, setPrFormVisibleColumns] = useState<PrFormVisibleColumns>(defaultPrFormVisibleColumns);

  const [editMode, setEditMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [editFreeItem, setEditFreeItem] = useState<IFreeItem>();
  const [virginFreeItem, setVirginFreeItem] = useState<IFreeItem>();
  const [unsavedChangesFreeItem, setUnsavedChangesFreeItem] = useState(false);

  const [productUnits, setProductUnits] = useState<IProductUnits>();
  const [suppliers, setSuppliers] = useState<ISuppliers>();
  const [baseUnits, setBaseUnits] = useState<IProductBaseUnit[]>();

  const [search, setSearch] = useState<PrFormSearchProps>(cloneDeep(initSearch));

  const [showSupplier, setShowSupplier] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  const { identifier, clientIdentifier } = params;

  const [searchParams] = useSearchParams();

  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");

    if (clientIdentifier && ((pr && pr.identifier === "new") || token)) {
      if (
        userHasPrPermission(user, clientIdentifier, "manager") ||
        userHasPrPermission(user, clientIdentifier, "employee")
      )
        setEditMode(true);
    }
  }, [user?.permissions]);

  useEffect(() => {
    if (user?.identifier && client?.showSuppliersFor?.includes(user.identifier)) setShowSupplier(true);
  }, [client]);

  const init = () => {
    if (!identifier) return;

    PrApi.getPr(api, identifier, clientIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const pr = response?.body?.pr as IPurchaseRequisition;

        if (pr) {
          if (pr.identifier === "new") {
            APP.setPageTitle(I18n.t("pr.newPr.title"));
          } else {
            APP.setPageTitle(`${FDN.I18n.t("pr.pr")} # ${pr.identifier}`);
          }
          setPr(pr);
        }

        const client = response?.body?.client as IClient;
        if (client) setClient(client);

        const virginPrProduct = response?.body?.virginPrProduct as IPrProduct;
        if (virginPrProduct) setVirginPrProduct(virginPrProduct);

        const virginFreeItem = response?.body?.virginFreeItem as IFreeItem;
        if (virginFreeItem) setVirginFreeItem(virginFreeItem);

        const suppliers = response?.body?.suppliers as ISuppliers;
        if (suppliers) setSuppliers(suppliers);

        setProductUnits(response?.body?.productUnits as IProductUnits);

        setBaseUnits(response?.body?.baseUnits as IProductBaseUnit[]);

        const categories = response?.body?.categories as IProductCategories;
        if (categories) setCategories(categories);
      }
    });
  };

  const switchFormProductsView = (view: PrFormProductsView) => {
    setFormProductsView(view);
  };

  const getProductsForList = (showOnlyStatus?: IPrProductStatusType, showOnlySelected?: boolean): IProducts => {
    if (showOnlyStatus) showOnlySelected = true;

    let tmpProducts: IProducts = [];

    if (!pr || !categories || !formProductsView) return tmpProducts;

    for (const category of categories) {
      if (category.products) {
        for (const product of category.products) {
          product.categoryName = category.name;
          if (formProductsView === "selected" || showOnlySelected === true || editMode === false) {
            if (pr.products?.find((p) => p.productIdentifier === product.identifier && p.amount > 0))
              tmpProducts.push(product);
          } else if (formProductsView === "all") tmpProducts.push(product);
        }
      }
    }

    tmpProducts = tmpProducts.filter((p) => {
      const prProduct = pr.products?.find((prp) => prp.productIdentifier === p.identifier);

      if (!prProduct) {
        if (formProductsView === "selected" || showOnlySelected === true) return false;
        else return true;
      }

      if (showOnlyStatus) {
        if (
          prProduct.status &&
          Object.keys(prProduct.status || {}).includes(showOnlyStatus) &&
          prProduct.status[showOnlyStatus] !== 0
        )
          return true;
      } else {
        if (!editMode && prProduct.perfectAmount === 0) return false;
        if (!prProduct.status || (prProduct.status && prProduct.perfectAmount <= prProduct.amount)) return true;
      }
    });

    if (!search) return tmpProducts;

    const products: IProducts = [];

    for (const product of tmpProducts) {
      let addToList = true;

      if (search.text) {
        const searchText = search.text.toLowerCase();

        addToList = false;

        if (product.name?.toLowerCase().includes(searchText)) addToList = true;
        if (product.productId?.toLowerCase().includes(searchText)) addToList = true;

        if (product.categoryName?.toLowerCase().includes(searchText)) addToList = true;

        if (product.suppliers) {
          for (const supplier of product.suppliers) {
            if (supplier.name?.toLowerCase().includes(searchText)) addToList = true;
          }
        }
      }

      if (addToList && search.supplier) {
        if (!product.supplierIdentifiers || product.supplierIdentifiers[0] !== search.supplier) addToList = false;
      }

      if (addToList && search.category) {
        if (product.categoryIdentifier !== search.category) addToList = false;
      }

      if (addToList === true) products.push(product);
    }

    if (search.sortBy) return sortProducts(products, search.sortBy);

    return products;
  };

  const sortProducts = (products: IProducts, sortBy: string): IProducts => {
    const [sortByKey, sortByDirection] = sortBy.split("_");

    let property = "";
    if (sortByKey === "unit") property = "unit";
    else if (sortByKey === "productName") property = "name";
    else if (sortByKey === "productCategory") property = "categoryName";
    else if (sortByKey === "supplier") property = "supplier";
    else if (sortByKey === "productId") property = "productId";
    else if (sortByKey === "priceUnit") property = "priceUnit";

    if (!property || (sortByDirection !== "asc" && sortByDirection !== "desc")) return products;

    const sorted = products.sort((a, b) => {
      if (property === "unit") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "name") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "categoryName") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "supplier") {
        if (sortByDirection === "asc")
          return (a.suppliers?.[0]?.name || "").localeCompare(b.suppliers?.[0]?.name || "");
        else return (b.suppliers?.[0]?.name || "").localeCompare(a.suppliers?.[0]?.name || "");
      } else if (property === "productId") {
        if (sortByDirection === "asc") return (a[property] || "").localeCompare(b[property] || "");
        else return (b[property] || "").localeCompare(a[property] || "");
      } else if (property === "priceUnit") {
        if (sortByDirection === "asc") return (a[property] || 0) - (b[property] || 0);
        else return (b[property] || 0) - (a[property] || 0);
      } else return 1;
    });

    return sorted;
  };

  const updatePrProduct = (productIdentifier: string, amount: number | undefined | null) => {
    if (!pr || !virginPrProduct || !categories) return;

    const product = getProductFromCategoriesList(productIdentifier);

    let prProduct = pr.products?.find((p) => p.productIdentifier === productIdentifier);

    if (prProduct) {
      if (amount === undefined || amount === null || Number.isNaN(amount) || amount < 0) {
        amount = 0;
        prProduct.amount = amount;
        prProduct.perfectAmount = amount;
        // } else if (amount < 0) {
        //   const arrayIndex = pr.products?.map((p) => p.identifier).indexOf(prProduct.identifier);
        //   if (arrayIndex || arrayIndex === 0) pr.products?.splice(arrayIndex, 1);
      } else {
        prProduct.amount = amount;
        prProduct.perfectAmount = amount;
      }
    } else {
      prProduct = cloneDeep(virginPrProduct);
      prProduct.identifier = `new_${v4()}`;
      prProduct.prIdentifier = pr.identifier;
      prProduct.productIdentifier = productIdentifier;
      prProduct.amount = !amount || Number.isNaN(amount) ? 0 : amount;
      prProduct.perfectAmount = !amount || Number.isNaN(amount) ? 0 : amount;

      if (product) {
        prProduct.productId = product.productId;
        prProduct.name = product.name;
        prProduct.unit = showUnit(product);
        prProduct.priceUnit = product.priceUnit;
      }

      if (!pr.products) pr.products = [];
      pr.products?.push(prProduct);
    }

    if (prProduct) {
      prProduct.priceTotal = calculatePrProductPriceTotal(prProduct);
      pr.priceTotal = calculatePriceTotal(pr);
    }

    setUnsavedChanges(true);

    setPr(cloneDeep(pr));

    if (editFreeItem) setEditFreeItem(undefined);
  };

  const highlightRow = (productIdentifier: string) => {
    const elementId =
      Math.min(window.screen.width, window.screen.height) < 768
        ? `pr_row_${productIdentifier}_m`
        : `pr_row_${productIdentifier}_d`;

    const element = document.getElementById(elementId);

    if (element) {
      element.classList.remove("bg-white");
      element.classList.add("bg-primaryHover");
      setTimeout(() => {
        if (element) {
          setTimeout(() => {
            element.style.transition = "background-color 1s";
            element.style.backgroundColor = "transparent";
            setTimeout(() => {
              element.classList.remove("bg-primaryHover");
              element.classList.add("bg-white");
              element.style.removeProperty("transition");
              element.style.removeProperty("background-color");
            }, 2000);
          }, 3000);
        }
      }, 0);

      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }

    if (editFreeItem) setEditFreeItem(undefined);
  };

  const getProductFromCategoriesList = (identifier: string): IProduct | false => {
    if (!categories) return false;
    for (const category of categories) {
      if (category.products) {
        const product = category.products.find((p) => p.identifier === identifier);
        if (product) return product;
      }
    }

    return false;
  };

  const calculatePrProductPriceTotal = (prProduct: IPrProduct): number => {
    return prProduct.perfectAmount * (prProduct.priceUnit || 0);
  };

  const calculatePriceTotal = (calculationPr: IPurchaseRequisition) => {
    if (!pr || !calculationPr) return 0;

    let priceTotal = 0.0;

    if (calculationPr.products) {
      for (const prProduct of calculationPr.products) {
        priceTotal += prProduct.priceTotal;
      }
    }

    if (calculationPr.freeItems) {
      for (const freeItem of calculationPr.freeItems) {
        priceTotal += freeItem.priceTotal || 0;
      }
    }

    return priceTotal;
  };

  function updateSearch<P extends keyof PrFormSearchProps, V extends PrFormSearchProps[P]>(property: P, value: V) {
    if (!search) return;
    const updatedSearch = cloneDeep(search);
    updatedSearch[property] = value;
    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    const updatedSearch = cloneDeep(initSearch);
    if (search.sortBy) updatedSearch.sortBy = search.sortBy;
    setSearch(updatedSearch);
  };

  function updatePr<P extends keyof IPurchaseRequisition, V extends IPurchaseRequisition[P]>(property: P, value: V) {
    if (!pr) return;
    pr[property] = value;

    if (property === "desiredDeliveryType" && value !== "DESIRED") pr.desiredDeliveryDate = undefined;

    setUnsavedChanges(true);

    setPr(cloneDeep(pr));
  }

  const onDuplicate = () => {
    if (!pr) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.buttons.duplicate.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            PrApi.duplicatePr(api, pr.identifier).then((response) => {
              if (response?.statusCode === StatusCode.SUCCESS) {
                const duplicatedPr = response?.body?.pr;
                if (duplicatedPr && duplicatedPr.identifier && duplicatedPr.identifier !== pr.identifier) {
                  const url = UrlService.url("purchaserequisitions.show", {
                    identifier: duplicatedPr.identifier,
                  });
                  window.location.href = `${url}?token=${v4()}`;
                }
              }
            });
          },
        },
      ],
    });
  };

  const onArrived = () => {
    if (!pr) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.buttons.arrived.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            PrApi.prArrived(api, pr.identifier).then((response) => {
              if (response?.statusCode === StatusCode.SUCCESS) {
                const updatedPr = response?.body?.pr;
                if (updatedPr) {
                  setPr(cloneDeep(updatedPr));
                  NOTIFICATIONS.showNotification(
                    "success",
                    I18n.t("pr.onArrived.success.title"),
                    I18n.t("pr.onArrived.success.text")
                  );
                }
              }
            });
          },
        },
      ],
    });
  };

  const onStartEditing = () => {
    if (!client || !pr || !pr.isEditable) return;

    const doStartEditing = () => {
      if (
        userHasPrPermission(user, client.identifier, "manager") ||
        userHasPrPermission(user, client.identifier, "employee")
      ) {
        setEditMode(true);
      }
    };

    if (pr.status !== "CREATED") {
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("pr.onStartEditing.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => {
              AdminApi.prChangeStatus(api, pr?.identifier, "CREATED", true).then((response) => {
                if (response?.statusCode === StatusCode.SUCCESS) {
                  const pr = response?.body?.pr;
                  if (pr) {
                    setPr(pr);
                    NOTIFICATIONS.hideDialog();
                    doStartEditing();
                  }
                }
              });
            },
          },
        ],
      });
    } else {
      doStartEditing();
    }
  };

  const onCancel = () => {
    if (!client) return;

    const doCancel = () => {
      window.location.href = UrlService.url("index", {
        clientIdentifier: client.identifier,
      });
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!pr || !client) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    if (pr.desiredDeliveryDate) pr.desiredDeliveryDate.setHours(12);

    PrApi.savePr(api, pr.identifier, pr.clientIdentifier, pr).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const pr = response?.body?.pr;
        setPr(pr);

        const virginPrProduct = response?.body?.virginPrProduct as IPrProduct;
        if (virginPrProduct) setVirginPrProduct(virginPrProduct);

        const categories = response?.body?.categories as IProductCategories;
        if (categories) setCategories(categories);

        setUnsavedChanges(false);
        NOTIFICATIONS.hideSaving();

        NOTIFICATIONS.showNotification("success", I18n.t("pr.onSave.sucess.title"), I18n.t("pr.onSave.sucess.text"));

        if (pr && pr.identifier) {
          const url = `${UrlService.url("purchaserequisitions.show", {
            clientIdentifier: client.identifier,
            identifier: pr.identifier,
          })}`;

          navigate(url, { replace: true });
        }
      }
    });
  };

  const onContinue = () => {
    if (!pr || !client) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    PrApi.savePr(api, pr.identifier, pr.clientIdentifier, pr).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const pr = response?.body?.pr;
        setPr(pr);

        setUnsavedChanges(false);
        NOTIFICATIONS.hideSaving();

        NOTIFICATIONS.showNotification("success", I18n.t("pr.onSave.sucess.title"), I18n.t("pr.onSave.sucess.text"));

        if (pr && pr.identifier) {
          const url = `${UrlService.url("purchaserequisitions.preview", {
            clientIdentifier: client.identifier,
            identifier: pr.identifier,
          })}`;

          window.location.href = url;
        }
      }
    });
  };

  const onSend = () => {
    if (!pr || !client) return;

    const doSend = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      PrApi.sendPr(api, pr.identifier, pr.clientIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const pr = response?.body?.pr;
          setPr(pr);

          setUnsavedChanges(false);
          NOTIFICATIONS.hideSaving();

          NOTIFICATIONS.showNotification("success", I18n.t("pr.onSend.sucess.title"), I18n.t("pr.onSend.sucess.text"));

          if (pr && pr.identifier) {
            const url = `${UrlService.url("purchaserequisitions.show", {
              clientIdentifier: client.identifier,
              identifier: pr.identifier,
            })}`;

            window.location.href = url;
          }
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.onSend.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doSend(),
        },
      ],
    });
  };

  const onCancelPr = () => {
    if (!pr || !client) return;

    const doCancelPr = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      PrApi.cancelPr(api, pr.identifier, pr.clientIdentifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const pr = response?.body?.pr;
          setPr(pr);

          setUnsavedChanges(false);
          NOTIFICATIONS.hideSaving();

          NOTIFICATIONS.showNotification(
            "success",
            I18n.t("pr.onCancel.sucess.title"),
            I18n.t("pr.onCancel.sucess.text")
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.onCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doCancelPr(),
        },
      ],
    });
  };

  const getFreeItemsForList = (showOnlyStatus?: IPrProductStatusType): IFreeItems => {
    if (!pr || !pr.freeItems) return [];

    let tmpFreeItems: IFreeItems = [];

    tmpFreeItems = pr.freeItems.filter((f) => {
      if (showOnlyStatus) {
        if (f.status && Object.keys(f.status || {}).includes(showOnlyStatus) && f.status[showOnlyStatus] !== 0)
          return true;
      } else {
        if (!editMode && f.perfectAmount === 0) return false;
        if (!f.status || (f.status && (f.perfectAmount || 0) <= (f.amount || 0))) return true;
      }
    });

    return tmpFreeItems;
  };

  const onSelectFreeItem = (freeItem: IFreeItem | "new") => {
    if (!virginFreeItem) return;

    if (freeItem === "new") {
      const newItem = cloneDeep(virginFreeItem);
      newItem.amount = 1;
      newItem.perfectAmount = 1;

      setEditFreeItem(newItem);
    } else setEditFreeItem(cloneDeep(freeItem));
  };

  function onEditFreeItem<P extends keyof IFreeItem, V extends IFreeItem[P]>(property: P, value: V) {
    if (!editFreeItem || !pr) return;

    if (property === "amount") {
      if (Number.isNaN(value)) {
        editFreeItem.amount = editFreeItem.amount || 0;
        editFreeItem.perfectAmount = editFreeItem.amount || 0;
      } else {
        editFreeItem.amount = value as number;
        editFreeItem.perfectAmount = value as number;
      }
    } else {
      editFreeItem[property] = value;
    }

    setEditFreeItem(cloneDeep(editFreeItem));
    setUnsavedChangesFreeItem(true);
  }

  const onEditFreeItemAmount = (identifier: string, value: number | undefined | null) => {
    if (!pr || !pr.freeItems) return;

    pr.freeItems = pr.freeItems.map((f) => {
      if (f.identifier === identifier) {
        if (!Number.isNaN(value)) {
          f.amount = (value as number) || 0;
          f.perfectAmount = (value as number) || 0;
        }
        f.priceTotal = (f.perfectAmount || 0) * (f.priceUnit || 0);
      }

      return f;
    });

    pr.priceTotal = calculatePriceTotal(pr);

    setPr(cloneDeep(pr));
  };

  const onFreeItemAddFile = async (files: FileUploadFile[]) => {
    if (!pr || !editFreeItem) return;

    for (const fileToBeUploaded of files) {
      fileToBeUploaded.identifier = `new_${v4()}`;

      setEditFreeItem((prev) => {
        if (prev) {
          return {
            ...prev,
            addedImages: [...(prev.addedImages ?? []), fileToBeUploaded],
          };
        }
        return prev;
      });

      const file = await uploadFile(fileToBeUploaded);
      if (!file) continue;

      if (file) {
        if (!editFreeItem.addedImages) editFreeItem.addedImages = [];
        editFreeItem.addedImages = editFreeItem.addedImages.filter((f) => f.identifier !== fileToBeUploaded.identifier);

        setEditFreeItem((prev) => {
          if (prev) {
            return {
              ...prev,
              filesIdentifiers: [...(prev.filesIdentifiers ?? []), file.identifier],
              files: [...(prev.files ?? []), file],
              addedImages: [...(editFreeItem.addedImages ?? [])],
            };
          }
          return prev;
        });
      }
    }
  };

  const onFreeItemRemoveFile = (index: number) => {
    if (!pr || !editFreeItem) return;

    const doRemove = () => {
      if (editFreeItem.filesIdentifiers) editFreeItem.filesIdentifiers.splice(index, 1);
      if (editFreeItem.files) editFreeItem.files.splice(index, 1);

      setEditFreeItem(cloneDeep(editFreeItem));
      setUnsavedChangesFreeItem(true);

      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.freeitems.form.images.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doRemove(),
        },
      ],
    });
  };

  const onCancelFreeItem = () => {
    if (!editFreeItem) return;

    const doCancel = () => {
      setEditFreeItem(undefined);
      setUnsavedChangesFreeItem(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChangesFreeItem)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const uploadFile = async (file: FileUploadFile): Promise<IFile | null> => {
    const response = await FilesApi.uploadFiles(api, "freeitems", [file]);

    if (response?.statusCode === StatusCode.SUCCESS) {
      if (response?.body?.files && response?.body?.urls) {
        return response.body.files[0] as IFile;
      }
    }

    return null;
  };

  const onSaveFreeItem = async () => {
    if (!editFreeItem || !pr) return;

    const baseUnit = baseUnits?.find((baseUnit) => baseUnit.identifier === editFreeItem.baseUnitIdentifier);
    if (baseUnit) editFreeItem.unit = `${editFreeItem.package} ${baseUnit.name}`;

    if (!pr.freeItems) pr.freeItems = [];

    if (editFreeItem.addedImages && editFreeItem.addedImages.length > 0) {
      return;
    }

    let isCompletelyNewFreeItem = true;

    pr.freeItems = pr.freeItems.map((freeItem) => {
      if (freeItem.identifier === editFreeItem.identifier) {
        isCompletelyNewFreeItem = false;
        return editFreeItem;
      } else {
        return freeItem;
      }
    });

    if (isCompletelyNewFreeItem === true) {
      editFreeItem.identifier = `new_${v4()}`;
      pr.freeItems.push(editFreeItem);
    }

    pr.priceTotal = calculatePriceTotal(pr);
    setPr(cloneDeep(pr));

    setEditFreeItem(undefined);
    setUnsavedChangesFreeItem(false);
  };

  const onAddFreeItemsFromCsv = (records: ProcessedFreeItemsCsvData[]) => {
    if (!pr) return;
    if (!pr.freeItems) pr.freeItems = [];

    for (const record of records) {
      const freeItem: IFreeItem = {
        identifier: `new_${v4()}`,
        prIdentifier: pr.identifier,
        name: record.name,
        amount: record.amount,
        perfectAmount: record.amount,
        priceUnit: record.priceUnit,
        unit: record.unit,
        description: record.description,
        priceTotal: (record.amount ?? 0) * (record.priceUnit ?? 0),
      };

      pr.freeItems.push(freeItem);
    }

    pr.priceTotal = calculatePriceTotal(pr);
    setPr(cloneDeep(pr));

    setEditFreeItem(undefined);
    setUnsavedChangesFreeItem(false);
  };

  const onDeleteFreeItem = (identifier: string) => {
    if (!editFreeItem || !pr || !pr.freeItems) return;

    const doDeleteFreeItem = () => {
      if (!pr.freeItems) return;

      pr.freeItems = pr.freeItems.map((f) => {
        if (f.identifier === identifier) f.deleted = true;
        return f;
      });

      pr.priceTotal = calculatePriceTotal(pr);

      setPr(cloneDeep(pr));
      setEditFreeItem(undefined);
      setUnsavedChangesFreeItem(false);
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("pr.freeitems.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            doDeleteFreeItem();
          },
        },
      ],
    });
  };

  const actions: TActionsPr = {
    switchFormProductsView,
    getProductsForList,
    updatePrProduct,
    highlightRow,
    updateSearch,
    resetSearch,
    updatePr,
    onDuplicate,
    onArrived,
    onStartEditing,
    getFreeItemsForList,
    onSelectFreeItem,
    onEditFreeItem,
    onEditFreeItemAmount,
    onFreeItemAddFile,
    onFreeItemRemoveFile,
    onCancelFreeItem,
    onSaveFreeItem,
    onAddFreeItemsFromCsv,
    onDeleteFreeItem,
    onCancel,
    onSave,
    onContinue,
    onSend,
    onCancelPr,
  };

  return {
    APP,
    user,
    client,
    editMode,
    search,
    pr,
    categories,
    formProductsView,
    prFormVisibleColumns,
    unsavedChanges,
    editFreeItem,
    productUnits,
    showSupplier,
    suppliers,
    baseUnits,
    actions,
  };
};

export default useServicePr;
