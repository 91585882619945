import * as React from "react";
import * as FDN from "src/core";
import { IFreeItem, TActionsPr } from "src/types/pr.types";
import { IProduct, IProductBaseUnit, IProductUnits } from "src/types/products.types";
import TabSingle from "./freeitems/TabSingle";
import TabMultiCSV from "./freeitems/TabMultiCSV";

const tabs: Record<string, FDN.ITabsTab> = {
  single: {
    icon: <FDN.Icon icon="plus-circle" />,
    label: FDN.I18n.t("pr.freeitems.tabs.single.label"),
  },
  multiple: {
    icon: <FDN.Icon icon="upload" />,
    label: FDN.I18n.t("pr.freeitems.tabs.multiple.label"),
  },
};

interface IPrFreeItemsPopupProps {
  freeItem: IFreeItem;
  missingFieldsFreeItem?: string[];
  productUnits: IProductUnits;
  products: IProduct[];
  baseUnits: IProductBaseUnit[];
  actions: TActionsPr;
}

const PrFreeItemsPopup: React.FunctionComponent<IPrFreeItemsPopupProps> = ({
  freeItem,
  missingFieldsFreeItem,
  productUnits,
  products,
  baseUnits,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<string>("single");

  return (
    <div className="__admin-products-popup-edit-product">
      <FDN.Popup
        size="small"
        title={freeItem.identifier === "new" ? FDN.I18n.t("pr.freeitems.title.new") : freeItem.name}
        onClose={actions.onCancelFreeItem}
      >
        <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={(tabKey) => setSelectedTab(tabKey)} />

        <div className="pt-4">
          {selectedTab === "single" && (
            <TabSingle
              freeItem={freeItem}
              missingFieldsFreeItem={missingFieldsFreeItem}
              productUnits={productUnits}
              baseUnits={baseUnits}
              products={products}
              actions={actions}
            />
          )}

          {selectedTab === "multiple" && <TabMultiCSV prActions={actions} />}
        </div>
      </FDN.Popup>
    </div>
  );
};

export default PrFreeItemsPopup;
