import {
  IFormatDateOptions,
  IFormatDateTimeOptions,
  IFormatTimeOptions,
  TFormatDateTimeDate,
} from "../types/helpers.types";
import { format } from "date-fns";

const DATEFORMAT__DATE = "dd.MM.yyyy";
const DATEFORMAT__TIME_WITHOUT_SECONDS = "HH:mm";
const DATEFORMAT__TIME_WITH_SECONDS = "HH:mm:ss";

const fallbackValue = "-"; // Fallback value if no date is given (or could not be transformed into Date object)
const glueForHumanReadableDate = `, `; // String to glue human readable date like "Today" to the time string
const glueForDefaultDate = ` - `; // String to glue default date like "13.01.2000" to the time string

const defaultFormatDateOptions: IFormatDateOptions = {
  useHumanReadableDate: true,
};

const defaultFormatTimeOptions: IFormatTimeOptions = {
  seconds: false,
};

const defaultFormatDateTimeOptions: IFormatDateTimeOptions = {
  ...defaultFormatDateOptions,
  ...defaultFormatTimeOptions,
};

export const formatDate = (
  date: TFormatDateTimeDate,
  options: IFormatDateOptions = defaultFormatDateOptions
): string => {
  if (!date || !checkIfIsDate(date)) return fallbackValue;
  const dateObj = new Date(date);

  let dateStr = format(dateObj, DATEFORMAT__DATE);

  if (options.useHumanReadableDate === true) {
    const humanReadableResult = tryToFormatToHumanReadableDate(dateObj);
    if (humanReadableResult) dateStr = humanReadableResult;
  }

  return dateStr;
};

export const formatTime = (
  date: TFormatDateTimeDate,
  options: IFormatTimeOptions = defaultFormatTimeOptions
): string => {
  if (!date || !checkIfIsDate(date)) return fallbackValue;
  const dateObj = new Date(date);

  if (options.seconds === true) return format(dateObj, DATEFORMAT__TIME_WITH_SECONDS);
  else return format(dateObj, DATEFORMAT__TIME_WITHOUT_SECONDS);
};

export const formatDateTime = (
  date: TFormatDateTimeDate,
  options: IFormatDateTimeOptions = defaultFormatDateTimeOptions
): string => {
  if (!date || !checkIfIsDate(date)) return fallbackValue;

  const dateStr = formatDate(date, options);
  const timeStr = formatTime(date, options);

  let dateTimeStr = `${dateStr}${glueForDefaultDate}${timeStr}`;
  if (["Today", "Tomorrow", "Yesterday"].includes(dateStr))
    dateTimeStr = `${dateStr}${glueForHumanReadableDate}${timeStr}`;

  return dateTimeStr;
};

const checkIfIsDate = (date: TFormatDateTimeDate): boolean => {
  if (!date) return false;

  const dateObj = new Date(date);

  try {
    if (isNaN(dateObj?.getTime())) return false;
    else return true;
  } catch {
    return false;
  }
};

const tryToFormatToHumanReadableDate = (date: Date): "Today" | "Tomorrow" | "Yesterday" | null => {
  if (isToday(date)) return "Today";
  if (isTomorrow(date)) return "Tomorrow";
  if (isYesterday(date)) return "Yesterday";
  return null;
};

const isToday = (date: Date): boolean =>
  date.toDateString() === new Date(Date.now()).toDateString();

const isYesterday = (date: Date): boolean =>
  date.toDateString() === new Date(Date.now() - 24 * 3600 * 1000).toDateString();

const isTomorrow = (date: Date): boolean =>
  date.toDateString() === new Date(Date.now() + 24 * 3600 * 1000).toDateString();
