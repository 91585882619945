import * as React from "react";

interface IBoxProps {
  noPadding?: boolean;
  noMargin?: boolean;
  small?: boolean;
  style?: { [key: string]: string };
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Box: React.FunctionComponent<IBoxProps> = (props) => {
  const { noPadding, noMargin, small, style, className, onClick } = props;

  const getClasses = () => {
    let classes = "__box";

    if (noPadding) classes += " no-padding";
    if (noMargin) classes += " no-margin";
    if (small) classes += " small-box";

    if (onClick) classes += " clickable";

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  return (
    <div
      className={getClasses()}
      style={style}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {props.children}
    </div>
  );
};

export default Box;
