import * as React from "react";
import * as FDN from "src/core";
import PrLayout from "./PrLayout";
import AdminHeader from "../admin/layouts/AdminHeader";
import useServicePr from "src/services/PrService";
import PrForm from "./parts/PrForm";
import { userHasPrPermission } from "src/core/AdminService/helpers";
import PrClientLogo from "./parts/PrClientLogo";
import PrStatus from "./parts/PrStatus";
import Api from "src/api/Api";
import PrFormMobile from "./parts/mobile/PrFormMobile";
import { IProductCategories, ISuppliers } from "src/types/products.types";

const PrView: React.FunctionComponent = () => {
  const {
    APP,
    pr,
    client,
    search,
    editMode,
    categories,
    prFormVisibleColumns,
    unsavedChanges,
    editFreeItem,
    productUnits,
    showSupplier,
    suppliers,
    baseUnits,
    actions,
  } = useServicePr();

  const user = APP.getUser();

  if (!pr || !client || !suppliers || !baseUnits) return <FDN.Loading />;

  const products = actions.getProductsForList();

  let filteredSuppliers: ISuppliers = [];

  for (const prProduct of pr.products ?? []) {
    const product = products.find((product) => product.identifier === prProduct.productIdentifier);
    if (product && product.suppliers) {
      filteredSuppliers.push(...product.suppliers);
    }
  }

  filteredSuppliers = filteredSuppliers.filter(
    (supplier, index, self) => index === self.findIndex((s) => s.identifier === supplier.identifier)
  );

  let filteredCategories: IProductCategories = [];

  for (const prProduct of pr.products ?? []) {
    const product = products.find((product) => product.identifier === prProduct.productIdentifier);
    if (product && product.categoryIdentifier) {
      const category = categories?.find((category) => category.identifier === product.categoryIdentifier);
      if (category) {
        filteredCategories.push(category);
      }
    }
  }

  filteredCategories = filteredCategories.filter(
    (supplier, index, self) => index === self.findIndex((s) => s.identifier === supplier.identifier)
  );

  return (
    <div className="__prs-view">
      <FDN.LayoutWithTopbar page="prs" clientLogoUrl={client.logoUrl}>
        <FDN.PageContent>
          <PrLayout>
            <div className="_prs-view-topline">
              <FDN.Grid full>
                <FDN.Row margin="xy">
                  <FDN.Cell sm={24} md={18} className="text-left">
                    {editMode === true ? (
                      <>
                        <div className="show-for-small-only">
                          <button className="small primary hollow button" onClick={actions.onCancel}>
                            <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.cancel")}
                          </button>{" "}
                          <button className="small primary button" onClick={actions.onSave}>
                            <FDN.Icon icon="save" left /> {FDN.I18n.t("buttons.tempsave")}
                          </button>
                        </div>
                        <div className="hide-for-small-only">
                          <button className="primary hollow button" onClick={actions.onCancel}>
                            <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.cancel")}
                          </button>{" "}
                          <button className="primary button" onClick={actions.onSave}>
                            <FDN.Icon icon="save" left /> {FDN.I18n.t("buttons.tempsave")}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {userHasPrPermission(user, pr.clientIdentifier, "manager") ||
                        userHasPrPermission(user, pr.clientIdentifier, "employee") ? (
                          <>
                            <div className="show-for-small-only">
                              <button className="small primary hollow button" onClick={actions.onCancel}>
                                <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.back")}
                              </button>{" "}
                              {pr.isEditable ? (
                                <button className="small primary button" onClick={actions.onStartEditing}>
                                  <FDN.Icon icon="pencil" left /> {FDN.I18n.t("buttons.edit")}
                                </button>
                              ) : null}{" "}
                              {pr.isCancelable ? (
                                <button className="small alert button" onClick={actions.onCancelPr}>
                                  <FDN.Icon icon="times-circle" left /> {FDN.I18n.t("pr.buttons.cancel.label")}
                                </button>
                              ) : null}{" "}
                              {pr.identifier !== "new" ? (
                                <>
                                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                  <button className="small primary hollow button" onClick={actions.onDuplicate}>
                                    <FDN.Icon icon="clone" left /> {FDN.I18n.t("pr.buttons.duplicate.label")}
                                  </button>{" "}
                                  {["PROCESS", "DELIVERY", "COMPLETED"].includes(pr.status) && !pr.statusDateARRIVED ? (
                                    <button className="success button" onClick={actions.onArrived}>
                                      <FDN.Icon icon="truck" left /> {FDN.I18n.t("pr.buttons.arrived.label")}
                                    </button>
                                  ) : null}
                                </>
                              ) : null}{" "}
                              {pr.pdfPath ? (
                                <a
                                  href={
                                    new Api().getEndpoint("purchaserequisitions.pdfDownload", {
                                      clientIdentifier: pr.clientIdentifier,
                                      identifier: pr.identifier,
                                    }) as string
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="small primary button"
                                >
                                  <FDN.Icon icon="file-pdf-o" left /> {FDN.I18n.t("adminPr.buttons.pdf.label")}
                                </a>
                              ) : null}
                            </div>
                            <div className="hide-for-small-only">
                              <button className="small primary hollow button" onClick={actions.onCancel}>
                                <FDN.Icon icon="angle-left" left /> {FDN.I18n.t("buttons.back")}
                              </button>{" "}
                              {pr.isEditable ? (
                                <button className="small primary button" onClick={actions.onStartEditing}>
                                  <FDN.Icon icon="pencil" left /> {FDN.I18n.t("buttons.edit")}
                                </button>
                              ) : null}{" "}
                              {pr.isCancelable ? (
                                <button className="small alert button" onClick={actions.onCancelPr}>
                                  <FDN.Icon icon="times-circle" left /> {FDN.I18n.t("pr.buttons.cancel.label")}
                                </button>
                              ) : null}{" "}
                              {pr.identifier !== "new" ? (
                                <>
                                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                  <button className="small primary hollow button" onClick={actions.onDuplicate}>
                                    <FDN.Icon icon="clone" left /> {FDN.I18n.t("pr.buttons.duplicate.label")}
                                  </button>{" "}
                                  {["PROCESS", "DELIVERY", "COMPLETED"].includes(pr.status) && !pr.statusDateARRIVED ? (
                                    <button className="success button" onClick={actions.onArrived}>
                                      <FDN.Icon icon="truck" left /> {FDN.I18n.t("pr.buttons.arrived.label")}
                                    </button>
                                  ) : null}
                                </>
                              ) : null}{" "}
                              {pr.pdfPath ? (
                                <a
                                  href={
                                    new Api().getEndpoint("purchaserequisitions.pdfDownload", {
                                      clientIdentifier: pr.clientIdentifier,
                                      identifier: pr.identifier,
                                    }) as string
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="small primary button"
                                >
                                  <FDN.Icon icon="file-pdf-o" left /> {FDN.I18n.t("adminPr.buttons.pdf.label")}
                                </a>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </FDN.Cell>
                  <FDN.Cell sm={24} md={6} className="text-center md:text-right">
                    <div className="hidden md:block">
                      <PrClientLogo client={client} />
                    </div>
                  </FDN.Cell>
                </FDN.Row>
              </FDN.Grid>
            </div>
            <AdminHeader
              title={
                pr.identifier === "new" ? FDN.I18n.t("pr.newPr.title") : `${FDN.I18n.t("pr.pr")} # ${pr.identifier}`
              }
              buttons={[]}
            />
            {pr.identifier !== "new" && <PrStatus pr={pr} location="view" />}
            {pr.identifier !== "new" && pr.statusDateSENT && !editMode ? (
              <>
                <div className="flex gap-2 flex-col md:flex-row md:justify-end mt-4 mb-2">
                  <div
                    className={`w-full md:w-1/4 border rounded px-4 py-2 ${
                      pr.statusDateSENT ? "bg-green-100 text-green-700" : "bg-slate-100 text-slate-500"
                    } text-center`}
                  >
                    <FDN.Icon icon={pr.statusDateSENT ? "check" : "times-circle"} left />
                    {FDN.I18n.t("pr.dateInfoBox.SENT.label")}{" "}
                    <span className="font-semibold">
                      {FDN.formatDateTime(pr.statusDateSENT, {
                        useHumanReadableDate: false,
                      })}
                    </span>
                  </div>
                  <div
                    className={`w-full md:w-1/4 border rounded px-4 py-2 ${
                      pr.statusDateARRIVED ? "bg-green-100 text-green-700" : "bg-slate-100 text-slate-500"
                    } text-center`}
                  >
                    <FDN.Icon icon={pr.statusDateARRIVED ? "check" : "times-circle"} left />
                    {FDN.I18n.t("pr.dateInfoBox.ARRIVED.label")}{" "}
                    <span className="font-semibold">
                      {FDN.formatDateTime(pr.statusDateARRIVED, {
                        useHumanReadableDate: false,
                      })}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-4"></div>
            )}
            <div className="show-for-small-only">
              <PrFormMobile
                location="client"
                pr={pr}
                search={search}
                editMode={editMode}
                categories={!pr.status || pr.status === "CREATED" ? categories : filteredCategories}
                prFormVisibleColumns={prFormVisibleColumns}
                editFreeItem={editFreeItem}
                productUnits={productUnits}
                showSupplier={showSupplier}
                suppliers={!pr.status || pr.status === "CREATED" ? suppliers : filteredSuppliers}
                baseUnits={baseUnits}
                actions={actions}
              />
            </div>
            <div className="hide-for-small-only">
              <PrForm
                location="client"
                pr={pr}
                search={search}
                editMode={editMode}
                categories={!pr.status || pr.status === "CREATED" ? categories : filteredCategories}
                prFormVisibleColumns={prFormVisibleColumns}
                editFreeItem={editFreeItem}
                productUnits={productUnits}
                showSupplier={showSupplier}
                suppliers={!pr.status || pr.status === "CREATED" ? suppliers : filteredSuppliers}
                baseUnits={baseUnits}
                actions={actions}
              />
            </div>
            {editMode === true ? (
              <div className="__prs-view-bottomline">
                <button className="large primary button" onClick={actions.onContinue}>
                  {FDN.I18n.t(`pr.buttons.${unsavedChanges === true ? `saveandcontinue` : `continue`}.label`)}
                  <FDN.Icon icon="angle-right" right />
                </button>
              </div>
            ) : null}
            <div>&nbsp;</div>
          </PrLayout>
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

export default PrView;
