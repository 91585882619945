import React from "react";
import ImageDropArea from "src/components/main/ImageUpload/ImageDropArea";
import * as FDN from "src/core";
import Config from "src/core/Config";
import useServiceCore from "src/services/CoreService";
import { IFreeItem, TActionsPr } from "src/types/pr.types";
import { IProduct, IProductBaseUnit, IProductUnits } from "src/types/products.types";
import FreeItemImagesPopup from "../FreeItemImagesPopup";

interface ITabSingleProps {
  freeItem: IFreeItem;
  missingFieldsFreeItem?: string[];
  productUnits: IProductUnits;
  products: IProduct[];
  baseUnits: IProductBaseUnit[];
  actions: TActionsPr;
}

export default function TabSingle({
  freeItem,
  missingFieldsFreeItem,
  productUnits,
  products,
  baseUnits,
  actions,
}: ITabSingleProps) {
  const [imagesPopupVisible, setImagesPopupVisible] = React.useState<boolean>(false);
  const [newUnit, setNewUnit] = React.useState<string>();

  const { api } = useServiceCore();

  const selectValuesUnits: { [key: string]: string } = {
    none: "",
  };

  for (const productUnit of productUnits) {
    selectValuesUnits[`unit_${productUnit}`] = productUnit;
  }
  selectValuesUnits.add = FDN.I18n.t("adminProducts.popup.product.form.addUnit.label");

  const foundProducts = products.filter((p) => {
    return freeItem.name && p.name && p.name.toLowerCase().includes(freeItem.name.toLowerCase());
  });

  const currency = Config.get("currency") as string;

  const selectValuesBaseUnits: { [key: string]: string } = {
    none: "",
  };

  for (const baseUnit of baseUnits) {
    selectValuesBaseUnits[`baseunit_${baseUnit.identifier}`] = baseUnit.name;
  }

  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={24}>
            <FDN.Input
              type="text"
              value={freeItem.name}
              label={FDN.I18n.t("pr.freeitems.form.name.label")}
              isMissing={missingFieldsFreeItem?.includes("name")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("name", value);
              }}
            />

            {foundProducts && foundProducts.length > 0 && (
              <div className="mt-2 border rounded-lg bg-orange-100">
                <div className="font-semibold px-2 py-2">
                  {FDN.I18n.t("pr.freeitems.form.name.foundProducts.title")}
                </div>
                <div className="border-b border-b-orange-300 px-4 py-1.5 flex justify-between items-center text-xs">
                  <div className="flex-grow">{FDN.I18n.t("pr.freeitems.form.name.foundProducts.head.name.title")}</div>
                  <div className="w-24 flex-shrink-0 text-right">
                    {FDN.I18n.t("pr.freeitems.form.name.foundProducts.head.unit.title")}
                  </div>
                  <div className="w-28 flex-shrink-0 text-right">
                    {FDN.I18n.t("pr.freeitems.form.name.foundProducts.head.priceUnit.title")}
                  </div>
                </div>
                <div className="max-h-[150px] overflow-y-auto">
                  <ul className="">
                    {foundProducts.map((p) => (
                      <li key={p.identifier} className="border-b border-orange-200">
                        <button
                          className="px-4 py-1.5 cursor-pointer w-full text-left duration-200 hover:bg-primaryHover flex justify-between items-center"
                          onClick={() => {
                            actions.highlightRow(p.identifier);
                          }}
                        >
                          <div className="flex-grow">
                            <strong>{p.name}</strong>
                          </div>
                          <div className="w-24 flex-shrink-0 text-right">
                            {p.amount !== 1 ? `${p.amount} x` : p.amount} {p.unit}
                          </div>
                          <div className="w-24 flex-shrink-0 text-right">
                            {p.priceIsVariable ? (
                              <>
                                {FDN.formatNumber(p.priceUnit || 0)} {currency} *
                              </>
                            ) : (
                              <>
                                {FDN.formatNumber(p.priceUnit || 0)} {currency}
                              </>
                            )}
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="textarea"
              rows={4}
              value={freeItem.description}
              label={FDN.I18n.t("pr.freeitems.form.description.label")}
              isMissing={missingFieldsFreeItem?.includes("description")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("description", value);
              }}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={8} md={8}>
            <FDN.Input
              type="number"
              value={freeItem.amount ? freeItem.amount : "0"}
              label={FDN.I18n.t("pr.freeitems.form.amount.label")}
              isMissing={missingFieldsFreeItem?.includes("amount")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("amount", value);
              }}
            />
          </FDN.Cell>
          <FDN.Cell sm={8} md={8}>
            <FDN.Input
              type="text"
              value={freeItem.package}
              label={FDN.I18n.t("adminProducts.popup.product.form.package.label")}
              isMissing={missingFieldsFreeItem?.includes("package")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("package", value);
              }}
            />
          </FDN.Cell>
          <FDN.Cell sm={8} md={8}>
            <FDN.Input
              type="select"
              selectValues={selectValuesBaseUnits}
              value={`baseunit_${freeItem.baseUnitIdentifier}`}
              label={FDN.I18n.t("adminProducts.popup.product.form.baseUnit.label")}
              isMissing={missingFieldsFreeItem?.includes("baseUnitIdentifier")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("baseUnitIdentifier", value.replace("baseunit_", ""));
              }}
            />
          </FDN.Cell>
          {/* <FDN.Cell sm={16} md={16}>
            {newUnit === undefined ? (
              <FDN.Input
                type="select"
                selectValues={selectValuesUnits}
                value={`unit_${freeItem.unit}`}
                label={FDN.I18n.t("pr.freeitems.form.unit.label")}
                isMissing={missingFieldsFreeItem?.includes("unit")}
                editMode={true}
                onUpdate={(value) => {
                  if (value === "add") setNewUnit("");
                  else actions.onEditFreeItem("unit", value.replace("unit_", ""));
                }}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                <div style={{ flexGrow: 1 }}>
                  <FDN.Input
                    type="text"
                    value={newUnit}
                    label={FDN.I18n.t("pr.freeitems.form.unit.label")}
                    isMissing={missingFieldsFreeItem?.includes("unit")}
                    editMode={true}
                    onUpdate={(value) => {
                      setNewUnit(value);
                      actions.onEditFreeItem("unit", value);
                    }}
                  />
                </div>
                <div>
                  <button
                    className="clickable-text"
                    onClick={() => {
                      setNewUnit(undefined);
                      actions.onEditFreeItem("unit", "");
                    }}
                    style={{ lineHeight: "44px" }}
                  >
                    <FDN.Icon icon="times" />
                  </button>
                </div>
              </div>
            )}
          </FDN.Cell> */}
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <FDN.Input
              type="currency"
              options={{ currency: Config.get("currency") as string }}
              value={freeItem.priceUnit}
              label={FDN.I18n.t("pr.freeitems.form.priceUnit.label")}
              isMissing={missingFieldsFreeItem?.includes("priceUnit")}
              editMode={true}
              onUpdate={(value) => {
                actions.onEditFreeItem("priceUnit", value);
              }}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={12}>
            <FDN.Input
              type="currency"
              options={{ currency: Config.get("currency") as string }}
              value={freeItem.priceUnit && freeItem.amount ? freeItem.priceUnit * freeItem.amount : "0"}
              label={FDN.I18n.t("pr.freeitems.form.priceTotal.label")}
              isMissing={missingFieldsFreeItem?.includes("priceUnit")}
              editMode={false}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <div>
        <div className="text-primary text-lg font-semibold mt-6 pb-2">
          {FDN.I18n.t("pr.freeitems.form.images.title")}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-4">
          {freeItem.files?.map((file, index) => {
            const style: React.CSSProperties = {};
            let label: string | null = null;

            if (file.filetype?.startsWith("image")) {
              style.backgroundImage = `url("${
                api.getEndpoint("files.file", {
                  identifier: file.identifier,
                }) as string
              }")`;
            } else if (file.filetype?.startsWith("application/pdf")) {
              label = file.filename || null;
            }

            return (
              <div key={index} className="relative">
                <button
                  className="absolute -top-1.5 -right-1.5 bg-white ring-1 rounded-full w-6 h-6 text-red-500 cursor-pointer duration-200 hover:scale-110 z-20"
                  onClick={() => actions.onFreeItemRemoveFile(index)}
                >
                  <FDN.Icon icon="times" />
                </button>
                <button
                  className="w-full h-24 mb-1 rounded-lg bg-cover bg-center bg-no-repeat cursor-zoom-in duration-200 z-10 hover:scale-105"
                  style={style}
                  onClick={() => setImagesPopupVisible(true)}
                >
                  <div className="w-full h-full rounded-lg border border-gray-400 hover:border-primary flex items-center justify-center text-xs px-2">
                    {label}
                  </div>
                </button>
              </div>
            );
          })}
          {freeItem.addedImages?.map((image, index) => {
            return (
              <div key={index} className="relative w-full h-24 mb-1">
                <div className="w-full h-full grid grid-cols-1 text-center items-center rounded-lg bg-cover bg-center bg-no-repeat duration-200 border border-gray-400 text-gray-400 text-xl">
                  <FDN.Icon icon="circle-o-notch fa-spin" />
                </div>
              </div>
            );
          })}
          <div className="w-full h-24">
            <ImageDropArea
              type="freeitems"
              accept={{ "image/*": [".png", ".jpg", ".jpeg", ".gif"], "application/*": [".pdf"] }}
              maxFiles={10}
              actions={{ onSelectFiles: actions.onFreeItemAddFile }}
            />
          </div>
        </div>
      </div>
      {imagesPopupVisible && <FreeItemImagesPopup freeItem={freeItem} onClose={() => setImagesPopupVisible(false)} />}
      <div>&nbsp;</div>
      <FDN.FormButtons
        onSave={actions.onSaveFreeItem}
        onCancel={actions.onCancelFreeItem}
        onDelete={freeItem.identifier === "new" ? undefined : () => actions.onDeleteFreeItem(freeItem.identifier)}
      />
    </>
  );
}
