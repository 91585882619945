import * as React from "react";
import Icon from "../Icon";
import { IDropdownButtonMenuItem, IDropdownButtonMenuItems } from "../../../types/types";

export interface IDropdownButtonProps {
  button?: React.ReactNode;
  activationMode: "click" | "hover";
  noBorder?: boolean;
  menuItems: IDropdownButtonMenuItems;
}

const DropdownButton: React.FunctionComponent<IDropdownButtonProps> = ({
  button,
  activationMode,
  noBorder,
  menuItems,
}) => {
  if (!button) {
    const buttonStyles: React.CSSProperties = {};
    if (noBorder === true) buttonStyles.borderWidth = 0;

    button = (
      <button className="primary hollow button" style={buttonStyles}>
        <Icon icon="ellipsis-h" />
      </button>
    );
  }

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLSpanElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener("resize", updateDropdownMenuPosition);
  }, []);

  React.useEffect(() => {
    updateDropdownMenuPosition();
  }, [menuIsOpen]);

  const updateDropdownMenuPosition = () => {
    if (!buttonRef.current || !menuRef.current) return;

    if (window.innerWidth < 860) {
      menuRef.current.style.left = "0";

      return;
    }

    const x = buttonRef.current.offsetLeft;
    const menuWidth = menuRef.current.offsetWidth;
    const buttonWidth = buttonRef.current.offsetWidth;
    if (x && menuWidth && buttonWidth)
      menuRef.current.style.left = `${x - menuWidth + buttonWidth}px`;

    const y = buttonRef.current.offsetTop;
    const buttonHeight = buttonRef.current.offsetHeight;
    if (y && buttonHeight) menuRef.current.style.top = `${y + buttonHeight}px`;
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  button = (
    <span
      className="__dropdownbutton-button-wrapper"
      ref={buttonRef}
      onClick={() => {
        if (activationMode === "click") toggleMenu();
      }}
      onMouseEnter={() => {
        if (activationMode === "hover") toggleMenu();
      }}
    >
      {button}
    </span>
  );

  return (
    <span className={`__dropdownbutton`}>
      {button}
      <div
        ref={menuRef}
        className="__dropdownbutton-menu"
        style={{ display: menuIsOpen === true ? "block" : "none" }}
      >
        <ul>
          {menuItems.map((menuItem, key) => {
            return (
              <li key={key}>
                {menuItem.onClick ? (
                  <button
                    onClick={() => {
                      if (menuItem.onClick) menuItem.onClick();
                    }}
                  >
                    <MenuItemContent menuItem={menuItem} />
                  </button>
                ) : (
                  <a
                    href={menuItem.url}
                    rel="noopener noreferer"
                    target={menuItem.newTab === true ? `blank` : undefined}
                  >
                    <MenuItemContent menuItem={menuItem} />
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </span>
  );
};

interface IMenuItemContent {
  menuItem: IDropdownButtonMenuItem;
}

const MenuItemContent: React.FunctionComponent<IMenuItemContent> = ({ menuItem }) => {
  return (
    <>
      {menuItem.icon && <Icon icon={menuItem.icon} left />} {menuItem.label}
    </>
  );
};

export default DropdownButton;
